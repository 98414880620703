import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faMapMarkerAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { apiURL } from "../config/config";
import queryString from "query-string";
import { useTranslation } from "react-i18next";


export default function Searchbar(props) {
    const { t } = useTranslation("common");

    //location data includes country, region and locations
    const [locationData, setLocationData] = useState([]);

    //search state just for text input
    const [search, setSearch] = useState({ value: "", selected: false });
    //selected country OR location OR region
    const [selected, setSelected] = useState({ periodFrom: moment().days(6), periodTo: moment().add(12), persons: 4 });
    //button disable state
    const [isDisabled, setIsDisabled] = useState({});

    const history = useHistory();
    const location = useLocation();

    //validate if required data is present, without it we cant search
    useEffect(() => {
        if (Object.keys(selected).length === 3) {
            setIsDisabled(false);
        }
    }, [selected]);

    useEffect(() => {
        // get the data from the url
        const queryData = queryString.parse(location.search);

        // check the query data (contains countries, locations or regions?)
        let locationFromQuery = "";
        if (locationData.length > 0) {
            if (queryData.countries) {
                locationFromQuery = locationData.filter((loc) => loc.id === Number(queryData.countries))[0].name;
            } else if (queryData.regions) {
                locationFromQuery = locationData.filter((loc) => loc.id === Number(queryData.regions))[0].name;
            } else if (queryData.locations) {
                locationFromQuery = locationData.filter((loc) => loc.id === Number(queryData.locations))[0].name;
            }
        }

        // set the search value to the location from query
        setSearch({ value: locationFromQuery, selected: true });
        // set the persons and the dates by the querydata
        setSelected({
            ...selected,
            persons: queryData.persons ? parseInt(queryData.persons) : 4,
            periodFrom: queryData.periodFrom ? moment(queryData.periodFrom, "DD-MM-YYYY") : moment().days(6),
            periodTo: queryData.periodTo ? moment(queryData.periodFrom, "DD-MM-YYYY") : moment().add(12),
        });
    }, [locationData]);

    function searchForBoats() {
        //we need these two params all time, convert dates to special american type for search
        let refactoredParams = { ...selected };
        refactoredParams.periodFrom = selected.periodFrom.format("DD.MM.YYYY");
        refactoredParams.periodTo = selected.periodTo.format("DD.MM.YYYY");

        let searchParams =
            "?" +
            Object.entries(refactoredParams)
                .map(([key, value]) => {
                    return key + "=" + value;
                })
                .join("&");

        //reload page, or navigate to page.
        history.push({ pathname: "/search", search: searchParams });
    }

    //Main effect to get all the locations from backend. This endpoint inculudes regions, locations and countries as well.
    useEffect(() => {
        //Have to load aleardy selected parameters if we reloading, and are on the result page.

        axios
            .get(apiURL + "catalouge/locations")
            .then((res) => {
                let refactored = [];

                Object.entries(res.data).forEach(([type, locations]) => {
                    return locations.forEach((loc) => {
                        let place = {
                            type: type,
                            id: loc.id,
                            name: loc.name.textEN,
                        };
                        if (loc.hasOwnProperty("regionId")) {
                            place.name = res.data.regions.find((x) => x.id === loc.regionId).name.textEN + " " + place.name;
                        }
                        if (loc.hasOwnProperty("countryId")) {
                            place.name = res.data.countries.find((x) => x.id === loc.countryId).name.textEN + " " + place.name;
                        }
                        refactored.push(place);
                    });
                });
                setLocationData(refactored);
            })
            .catch((err) => {
                console.log("failed to load locations", err);
            });
    }, []);

    //check if date avalible and disable button if not
    function checkDate(date) {
        //enable all saturday.
        if (!(moment(date).day() === 6)) {
            return "disabled";
        }
    }

    function setAutomaticEndDate(date) {
        //will set automatic 1 week endDate
        setSelected({ ...selected, periodFrom: moment(date), periodTo: moment(date).add(7, "days") });
    }

    function updateSearch(text) {
        setSearch({ value: text, selected: false });
    }

    //This is temporary, we have to somehow separate the location /region/counrie key because if you change it and its different, both will be servet to backend and the search will be failed
    function updateLocation(loc) {
        let temp = { ...selected };
        delete temp.locations;
        delete temp.countries;
        delete temp.regions;

        setSelected({ ...temp, [loc.type]: [loc.id] });
    }

    return (
        <div className="search-container">
            <div className="content">
                <h1 className="title text-secondary">{t("Hajózzon velünk")}</h1>
                <div className="search-items d-md-flex justify-content-between">
                    <div className="search-item-container place">
                        <div className="search-item d-flex d-md-block d-xl-flex align-items-center text-center bg-secondary">
                            <div className="item-title d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary" />
                                <label className="mb-0 text-primary text-uppercase" htmlFor="from">
                                    {" "}
                                    {t("Honnan")}{" "}
                                </label>
                            </div>
                            <input
                                id="from"
                                type="text"
                                placeholder="Croatia, Turkey ..."
                                className="text-md-center text-xl-left"
                                autoComplete="off"
                                value={search.value}
                                onChange={(evt) => {
                                    updateSearch(evt.target.value);
                                }}
                            ></input>
                            <div className="loc-selector">
                                {locationData.map((loc) => {
                                    if (search.value !== "" && loc.name.toLowerCase().includes(search.value.toLocaleLowerCase()) && !search.selected) {
                                        return (
                                            <p
                                                key={loc.id}
                                                onClick={() => {
                                                    setSearch({ value: loc.name, selected: true });
                                                    updateLocation(loc);
                                                }}
                                                className="location-select"
                                            >
                                                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary" /> {loc.name}
                                            </p>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="search-item-container bed">
                        <div className="search-item d-flex d-md-block d-xl-flex align-items-center text-center bg-secondary">
                            <div className="item-title d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faBed} className="text-primary" />
                                <label className="mb-0 text-primary text-uppercase" htmlFor="bed">
                                    {t("Ágyak")}
                                </label>
                            </div>
                            <div className="counter d-flex justify-content-center">
                                <FontAwesomeIcon icon={faMinus} className="minus text-light" onClick={() => setSelected({ ...selected, persons: selected.persons === 0 ? 0 : selected.persons - 1 })} />
                                <input className="text-warning" id="bed" type="number" value={selected.persons} readOnly ></input>
                                <FontAwesomeIcon icon={faPlus} className="plus text-light" onClick={() => setSelected({ ...selected, persons: selected.persons === 15 ? 15 : selected.persons + 1 })} />
                            </div>
                        </div>
                    </div>
                    <div className="search-item-container time">
                        <div className="search-item d-flex d-md-block d-xl-flex align-items-center text-center bg-secondary">
                            <div className="item-title d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faCalendar} className="text-primary" />
                                <label className="mb-0 text-primary text-uppercase" htmlFor="when">
                                    {t("Mikor")}
                                </label>
                            </div>
                            <DatePicker dateFormat="yyyy-MM-dd" selected={new Date(selected.periodFrom)} onChange={(date) => setAutomaticEndDate(date)} dayClassName={(date) => checkDate(date)} value={selected.periodFrom} />
                        </div>
                    </div>
                    <div className="search-item-container search-btn-container d-flex align-items-center">
                        <button disabled={isDisabled} className="search-btn bg-primary text-secondary" onClick={() => searchForBoats()}>
                            {t("Keresés")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
