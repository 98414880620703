import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";


export default function SearchResult(props) {
    const { t } = useTranslation("common");

    const history = useHistory();

    const handleOnClick = (boat) => {
        //save current url

        history.push({
            pathname: "/details",
            search: "?" + "yachtId=" + boat.yacht.id + "&periodFrom=" + boat.periodFrom + "&periodTo=" + boat.periodTo,
            state: { boat: boat },
        });
    };

    const setSorting = (e) => {
        let tempArr = e.target.value.split(",");
        props.setUsedFilters({
            ...props.usedFilters,
            orderby: tempArr[0],
            desc: tempArr[1],
        });
    };

    function checkActiveFilter(key, value) {
        if (value !== "" && key !== "orderby" && key !== "lengthFrom" && key !== "lengthTo" && key !== "desc") {
            if (Array.isArray(value)) {
                if (value.length) {
                    return (
                        <span>
                            {key}: {value.map((x) => x + ", ")}
                        </span>
                    );
                }
            } else {
                return (
                    <span>
                        {key}: {value}
                    </span>
                );
            }
        }
    }

    function openSidebar() {
        let sidebar = document.querySelector(".filter-container");
        let body = document.querySelector("body");
        sidebar.classList.add("show");
        body.classList.add("blur");
    }

    useEffect(() => {
        let body = document.querySelector("body");
        let sidebar = document.querySelector(".filter-container");

        window.addEventListener("resize", function (event) {
            if (window.innerWidth > 991) {
                body.classList.remove("blur");
                sidebar.classList.remove("show");
            }
        });
    });

    function getProperDateValues(boat) {
        let from = moment(boat.periodFrom, "DD.MM.YYYY").format('YYYY.MM.DD')
        let to = moment(boat.periodTo, "DD.MM.YYYY").format("YYYY.MM.DD")

        return from + " - " + to
    }

    return (
        <div className="search-results">
            <div className="top-container d-lg-flex justify-content-between text-center">
                <h2 className="main-title text-warning mb-4 mb-lg-0">{t("Találati lista")}</h2>
                <div className="px-3 d-flex align-items-center justify-content-between">
                    <div className="open-menu text-left" onClick={openSidebar}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <select name="order" id="order" defaultValue="2,0" className="border-0 text-primary" onChange={setSorting}>
                        <option value="2,0">{t("Ár szerint növekvő")}</option>
                        <option value="2,1">{t("Ár szerint csökkenő")}</option>
                        <option value="3,0">{t("Hossz szerint növekvő")}</option>
                        <option value="3,1">{t("Hossz szerint csökkenő")}</option>
                        <option value="4,0">{t("Kabinok szerint növekvő")}</option>
                        <option value="4,1">{t("Kabinok szerint csökkenő")}</option>
                        <option value="5,0">{t("Kor szerint növekvő")}</option>
                        <option value="5,1">{t("Kor szerint csökkenő")}</option>
                    </select>
                </div>
            </div>
            {/* <Row className="active-filters-container">
                <p className="active-title">Aktiv szürők: </p>
                {Object.entries(props.usedFilters).map(([key, value]) => {
                    return checkActiveFilter(key, value)
                })}
                <button className="clearbutton" onClick={() => console.log('reset filters')}>Szűrők törlése</button>
            </Row> */}
            <Row>
            {props.isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {props.yachts.length ? (
                            props.yachts.map((boat) => {
                                return (
                                    <Col key={boat.yachtId} sm={6} lg={4}>
                                        <div className="ship-box h-100 d-flex flex-column">
                                            <div className="image-container">
                                                <img src={boat.yacht.mainPictureUrl} alt="ship" onClick={() => handleOnClick(boat)} />
                                                {boat.price.discounts.length ? <p className="discount text-secondary bg-warning">-{boat.price.discounts[0].amount}%</p> : null}
                                            </div>
                                            <div className="box-content flex-grow-1 d-flex flex-column">
                                                <h4 className="title text-primary"> {boat.yacht.name} </h4>
                                                <p className="year text-primary">({boat.yacht.buildYear})</p>
                                                <p className="availability text-primary">{getProperDateValues(boat)}</p>
                                                <div className="flex-grow-1 d-flex flex-column justify-content-end">
                                                    <div>
                                                        <p className="price text-secondary">€{parseInt(boat.price.clientPrice).toFixed(0)}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center align-items-lg-start align-items-xl-center  flex-lg-column flex-xl-row">
                                                        <p className="old-price text-light">
                                                            <span>€</span>
                                                            {boat.price.priceListPrice}
                                                        </p>

                                                        <button onClick={() => handleOnClick(boat)} className="view-btn border-0 bg-transparent text-warning d-block ml-auto ml-lg-0 ml-xl-auto">
                                                            {t("Megnézem")}
                                                            <FontAwesomeIcon className="d-none d-sm-inline-block" icon={faArrowRight} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        ) : (
                            <p style={{ margin: "auto", color: "grey" }}>{t("A megadott feltételekkel jelenleg nincs elérhető yacht.")}</p>
                        )}
                    </>
                )}
            </Row>
        </div>
    );
}
