import React, { useState, useEffect } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons"
import RegisterModal from "./RegisterModal"
import LoginModal from "./LoginModal"
import { connect } from "react-redux"
import { getIsLoggedIn, toggleIsLoggedIn } from "../state/store"
import { Cookies } from "react-cookie"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import logo from "../assets/images/logo.svg"
import mobileLogo from "../assets/images/yachtpick-logo-RGB_positive-cut.svg"
import ForgotPassword from "./ForgotPasswordModal"
import { HashLink } from 'react-router-hash-link';


function Header(props) {
    const cookies = new Cookies()
    const history = useHistory()

    function openNavbar() {
        let collapseNavbar = document.querySelector(".collapse-navbar")
        let body = document.querySelector("body")
        collapseNavbar.classList.add("show")
        body.classList.add("blur")
    }

    function closeNavbar() {
        let collapseNavbar = document.querySelector(".collapse-navbar")
        let body = document.querySelector("body")
        collapseNavbar.classList.remove("show")
        body.classList.remove("blur")
    }

    useEffect(() => {
        let body = document.querySelector("body")
        let collapseNavbar = document.querySelector(".collapse-navbar")

        window.addEventListener("resize", function (event) {
            if (window.innerWidth > 991) {
                body.classList.remove("blur")
                collapseNavbar.classList.remove("show")
            }
        })
    }, [])

    /**
     * States for login and registration modals.
     */
    const [showLogin, setShowLogin] = useState(false)
    const toggleShowLogin = () => setShowLogin(!showLogin)

    const [showForgotPassword, setshowForgotPassword] = useState(false)

    const [showRegister, setShowRegister] = useState(false)
    const handleCloseRegister = () => setShowRegister(false)
    const handleShowRegister = () => {
        setShowLogin(false)
        setShowRegister(true)
    }

    /**
     * Handle logout, and close navbar on mobile
     */
    function handleLogOut() {
        props.toggleIsLoggedIn()
        cookies.remove("token", { path: "/" })
        closeNavbar()
    }

    function closeNavbarOnLogin() {
        closeNavbar()
    }

    // language selector
    const [t, i18n] = useTranslation("common")

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    function handleNavigate() {
        closeNavbar();
        history.push('/profile');
    }

    return (
        <div>
            <Navbar expand="lg">
                <div className="container-fluid">
                    <HashLink to="/" className="navbar-brand">
                        <img className="logo" src={logo} alt="logo" />
                        <img className="logo mobile" src={mobileLogo} alt="logo" />
                    </HashLink>
                    <Navbar.Toggle
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar"
                        aria-controls="navbarTogglerDemo02"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={openNavbar}
                        className="pl-0">
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <div className="collapse-navbar d-lg-flex align-items-center text-lg-center" id="navbar">
                        <span className="navbar-close-btn text-secondary d-lg-none" onClick={closeNavbar}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Nav className="ml-auto">
                            <HashLink to="/howitworks" className="nav-link text-secondary d-flex align-items-center justify-content-center">
                                {t("Hogyan működik?")}
                            </HashLink>
                            <HashLink to="/#special-banner" className="nav-link text-secondary d-flex align-items-center justify-content-center">
                                {t("Speciális ajánlatok")}
                            </HashLink>
                            <HashLink to="/contacts" className="nav-link text-secondary d-flex align-items-center justify-content-center">
                                {t("Kapcsolat")}
                            </HashLink>
                        </Nav>
                        {!props.login ? (
                            <button className="login-btn text-secondary bg-warning border-0" onClick={toggleShowLogin}>
                                {t("Belépés")}
                            </button>
                        ) : (
                            <div className="logout-button-group">
                                <button className="login-btn text-secondary bg-primary border-0" onClick={handleLogOut}>
                                    {t("Kijelentkezés")}
                                </button>
                                <FontAwesomeIcon onClick={() => handleNavigate()} size="2x" icon={faUser} />
                            </div>
                        )}
                        <div className="language-container d-flex justify-content-center">
                            <p className={"hu text-secondary mb-0" + (i18n.language === "hu" ? " active" : "")} onClick={() => changeLanguage("hu")}>
                                HU
                            </p>
                            <p className={"en text-secondary mb-0" + (i18n.language === "en" ? " active" : "")} onClick={() => changeLanguage("en")}>
                                EN
                            </p>
                        </div>
                    </div>
                </div>
            </Navbar>

            {/* LOGIN MODAL */}
            <LoginModal
                setshowForgotPassword={() => setshowForgotPassword(true)}
                showLogin={showLogin}
                toggleShowLogin={toggleShowLogin}
                handleShowRegister={handleShowRegister}
                closeNavbarOnLogin={closeNavbarOnLogin}
            />
            {/* LOGIN MODAL */}

            {/* REGISTER MODAL */}
            <RegisterModal handleShowRegister={handleShowRegister} handleCloseRegister={handleCloseRegister} showRegister={showRegister} />
            {/* REGISTER MODAL */}
            
            {/* FORGOT PASSWORD MODAL */}
            <ForgotPassword show={showForgotPassword} close={() => setshowForgotPassword(false)} />
            {/* FORGOT PASSWORD MODAL */}


        </div>
    )
}

const mapStateToProps = (state) => ({
    login: getIsLoggedIn(state),
})

const mapDispatchToProps = { toggleIsLoggedIn }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
