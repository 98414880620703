import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faUser, faBed, faDoorClosed, faShip, faRulerHorizontal, faRulerVertical, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import axios from "axios"
import { apiURL } from "../config/config"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Loader from "../components/Loader"

export default function LastMinuteBanner() {
    const { t } = useTranslation("common")

    const [discountedYachts, setDiscountedYachts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(apiURL + "yachts/discount")
            .then((res) => {
                setDiscountedYachts(res.data)
            })
            .catch((err) => {
                console.log("failed to load locations", err)
            })
        setIsLoading(false)
    }, [])

    const history = useHistory()

    const handleOnClick = (choosedYacht) => {
        history.push({
            pathname: "/details",
            search: "?yachtId=" + choosedYacht.yacht.id + "&periodFrom=" + choosedYacht.periodFrom + "&periodTo=" + choosedYacht.periodTo,
            state: { boat: choosedYacht },
        })
    }

    return (
        <div className="last-minute-banner">
            <h2 className="main-title text-warning text-center text-lg-left">{t("Last minute ajánlatok")}</h2>
            <Row>
                {isLoading ? (
                    <Loader />
                ) : (
                    discountedYachts.map((ship) => {
                        return (
                            <Col lg={4} className="mb-4" key={ship.yachtId}>
                                {ship.yacht && (
                                    <div className="last-minute-ship-box h-100 d-flex flex-column">
                                        <div className="image-container">
                                            <img src={ship.yacht.mainPictureUrl} alt="ship" />
                                        </div>
                                        <div className="box-content flex-grow-1 d-flex flex-column">
                                            <p className="ship-title">{ship.yacht.name}</p>
                                            <p className="ship-year">({ship.yacht.buildYear})</p>
                                            <div className="location d-flex">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                {ship.locationFromName && <p>{ship.locationFromName.name.textEN}</p>}
                                            </div>
                                            <div className="capacity information-item">
                                                <h4 className="info-title">{t("Férőhelyek")}</h4>
                                                <div className="content d-flex justify-content-between">
                                                    <div className="people info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faUser} />
                                                        <p>
                                                            {ship.yacht.cabins}
                                                            <span> {t("fő")}</span>
                                                        </p>
                                                    </div>
                                                    <div className="bed info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faBed} />
                                                        <p>
                                                            {ship.yacht.cabins}
                                                            <span> {t("ágy")}</span>
                                                        </p>
                                                    </div>
                                                    <div className="cabin info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faDoorClosed} />
                                                        <p>
                                                            {ship.yacht.cabins}
                                                            <span> {t("kabin")}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="physical-data information-item">
                                                <h4 className="info-title">{t("Fizikai adatok")}</h4>
                                                <div className="content d-flex justify-content-between">
                                                    <div className="ship info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faShip} />
                                                        <p>{ship.yacht.name}</p>
                                                    </div>
                                                    <div className="length info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faRulerHorizontal} />
                                                        <p>
                                                            {10}
                                                            <span> m</span>
                                                        </p>
                                                    </div>
                                                    <div className="height info d-flex align-items-center">
                                                        <FontAwesomeIcon icon={faRulerVertical} />
                                                        <p>
                                                            {20}
                                                            <span> m</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="availability information-item">
                                                <h4 className="info-title">{t("Időszak")}</h4>
                                                <div className="content d-flex">
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                    <p>
                                                        {ship.periodFrom} - {ship.periodTo}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column justify-content-end">
                                                <div className="price-box d-flex align-items-center">
                                                    <p className="current-price text-warning">
                                                        <span>€</span>
                                                        {ship.price.clientPrice}
                                                    </p>
                                                    <p className="old-price text-light">
                                                        <span>€</span>
                                                        {ship.price.priceListPrice}
                                                    </p>
                                                </div>
                                                <button className="view-btn ml-auto p-0 text-warning d-block border-0 bg-transparent" onClick={() => handleOnClick(ship)}>
                                                    {t("Megnézem")}
                                                    <FontAwesomeIcon className="text-warning" icon={faArrowRight} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        )
                    })
                )}
            </Row>
        </div>
    )
}
