import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/yachtpick-logo-RGB_negative_cut.svg";
import { HashLink } from 'react-router-hash-link';

export default function Footer(props) {
    // LANGUAGE //
    const [t, i18n] = useTranslation("common");
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <footer className="bg-primary">
            <Row>
                <Col xs={12} sm={12} lg={2} className="text-center text-lg-left">
                    <img src={logo} alt="logo" className="logo" />
                </Col>
                <Col xs={12} sm={6} lg={2} className="text-center text-sm-left">
                    <h2 className="footer-title text-warning">{t("Foglalás")}</h2>
                    <ul className="list-unstyled">
                        <li>
                            <HashLink to={"/howitworks"} className="text-secondary">{t("Hogyan működik?")}</HashLink>
                        </li>
                        <li>
                            <HashLink to={"/#special-banner"} className="text-secondary">{t("Speciális ajánlatok")}</HashLink>
                        </li>
                        <li>
                            <HashLink to={"/contacts"} className="text-secondary">{t("Kapcsolat")}</HashLink>
                        </li>
                    </ul>
                </Col>
                <Col xs={12} sm={6} lg={2} className="text-center text-sm-left">
                    <h2 className="footer-title text-warning">{t("Kapcsolat")}</h2>
                    <ul className="list-unstyled">
                        <li className="text-secondary">info@yachtbook.com</li>
                        <li className="text-secondary">+36201234565</li>
                    </ul>
                </Col>
                <Col xs={12} sm={6} lg={2} className="text-center text-sm-left">
                    <h2 className="footer-title text-warning">{t("Információ")}</h2>
                    <ul className="list-unstyled">
                        <li>
                        <HashLink to={"/privacy-statement"} className="text-secondary">{t("Adatvédelmi nyilatkozat")}</HashLink>

                        </li>
                        <li>
                            <a href="#" className="text-secondary">
                                {t("Impresszum")}
                            </a>
                        </li>
                        <li>
                            <HashLink to={"/terms-of-use"} className="text-secondary">{t("Felhasználási feltételek")}</HashLink>
                        </li>
                    </ul>
                </Col>
                <Col xs={12} sm={6} lg={2}>
                    <div className="actions language-container d-flex justify-content-center justify-content-sm-start justify-content-xl-end">
                        <p className={"hu text-secondary mb-0 d-flex" + (i18n.language === "hu" ? " active" : "")} onClick={() => changeLanguage("hu")}>
                            HU
                        </p>
                        <p className={"en text-secondary mb-0 d-flex" + (i18n.language === "en" ? " active" : "")} onClick={() => changeLanguage("en")}>
                            EN
                        </p>
                    </div>
                </Col>
            </Row>
            <div className="bottom-footer-container bg-secondary">
                <div className="bottom-footer d-md-flex justify-content-between text-center">
                    <p className="copyright text-primary mb-0">© 2020 YachtPick - {t("Minden jog fenntartva")}</p>
                    <div className="social d-sm-flex justify-content-center align-items-center">
                        <p className="text-warning mb-0">
                            {t("Kövess minket")}
                            <FontAwesomeIcon className="d-none d-sm-inline-block" icon={faArrowRight} />
                        </p>
                        <div className="icon-container d-flex justify-content-center">
                            <a href="#" className="icon m-2 text-secondary bg-primary d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="#" className="icon m-2 text-secondary bg-primary d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a href="#" className="icon m-2 text-secondary bg-primary d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                            <a href="#" className="icon m-2 text-secondary bg-primary d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
