import axios from "axios"
import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { apiURL } from "../config/config"
import { useTranslation } from "react-i18next"
import Loader from "./Loader"

export default function SpecialBanner() {
    const { t } = useTranslation("common")

    const history = useHistory()

    const [offer, setOffer] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        try {
            setIsLoading(true)
            axios.get(apiURL + "yachts/specialoffer").then((response) => {
                setOffer(response.data)
            })
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const handleOnClick = () => {
        history.push({
            pathname: "/details",
            search: "?yachtId=" + offer.yacht.id + "&periodFrom=" + offer.periodFrom + "&periodTo=" + offer.periodTo,
            state: { boat: offer },
        })
        window.scrollTo(0, 0)
    }

    return (
        <div className="special-banner" id="special-banner">
            <Row className="content text-primary">
                <Col xs={12} sm={6}>
                    <h2 className="main-title text-secondary">{t("A YachtPick speciális ajánlata")}</h2>

                    {isLoading ? (
                        <Loader />
                    ) : (
                        offer &&
                        offer.yacht && (
                            <>
                                <h4 className="ship-name">{offer.yacht.name}</h4>
                                <p className="year">{offer.yacht.buildYear}</p>
                                <p className="date">
                                    {offer.periodFrom}-{offer.periodTo}
                                </p>
                                <p className="price text-secondary">€{offer.price.clientPrice}</p>
                                <button className="view-btn text-secondary bg-primary border-0" onClick={handleOnClick}>
                                    {t("Megnézem")}
                                </button>
                            </>
                        )
                    )}
                </Col>
            </Row>
        </div>
    )
}
