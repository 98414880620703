import React, { useEffect, useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import MDEditor from "@uiw/react-md-editor"
import axios from "axios"
import { apiURL } from "../config/config"
import { Cookies } from "react-cookie"

function PrivacyStatemant() {
    const cookies = new Cookies()

    const [privacyStatement, setPrivacyStatement] = useState("")

    useEffect(() => {
        fetchPrivacyStatement()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchPrivacyStatement() {
        try {
            const response = await axios.get(apiURL + "markdown/privacy-statement", {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            })
            console.log("response", response.data)
            setPrivacyStatement(response.data.markDownText)
        } catch (error) {
            console.log("Failed to update the text")
        }
    }

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="privacy-statemant">
                <div className="content text-primary">
                    <h2 className="main-title text-warning text-center text-lg-left">Adatvédelmi nyilatkozat</h2>
                    <div className="content-block">
                        <MDEditor.Markdown source={privacyStatement} />
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

export default PrivacyStatemant
