import React, { useState, useEffect } from "react"
import Footer from "../Footer"
import Header from "../Header"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Link, useHistory, useParams } from "react-router-dom"
import axios from "axios"
import { Cookies } from "react-cookie"
import { apiURL } from "../../config/config"
import { useTranslation } from "react-i18next";


export default function UserReservationDetails(props) {
    const { t } = useTranslation("common");

    const [reservation, setReservation] = useState({})

    const history = useHistory()
    const cookies = new Cookies()
    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
        getReservationData()
    }, [])

    async function getReservationData() {
        try {
            let reservation = await axios.get(apiURL + "user/reservation/" + id, { headers: { Authorization: "Bearer " + cookies.get("token") } })
            setReservation(reservation.data)
        } catch (error) {}
    }

    function displayStatus(reservationStatus) {
        if (reservationStatus === "OPTION") {
            return "Foglalási kérelem"
        } else if (reservationStatus === "RESERVATION") {
            return "Elfogadott foglalás"
        } else if (reservationStatus === "STORNO") {
            return "Törölt foglalás"
        } else {
            return reservationStatus
        }
    }

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="reservation-data-container">
                <div className="content">
                    <button className="back-btn border-0 bg-transparent text-warning" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        {t("Vissza")}
                    </button>
                    <Row>
                        <h2 className="main-title text-warning text-center text-lg-left">{t("Foglalás részletei")}</h2>
                    </Row>

                    {/* show yacht informations */}
                    {reservation ? (
                        <Row className="justify-content-between">
                            <Col md={5} className="details-container text-primary">
                                <div className="detail-box">
                                    <h5 className="title">{t("Yacht neve")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <Link className="details text-warning text-md-left d-block" to={"/details?yachtId=" + reservation.yachtId}>
                                            {reservation.yachtName} <FontAwesomeIcon icon={faArrowRight} />
                                        </Link>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="detail-box">
                                    <h5 className="title">{t("Foglalás státusza")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <p>{t(displayStatus(reservation.reservationStatus))}</p>
                                    </div>
                                </div>
                                <div className="detail-box">
                                    <h5 className="title">{t("Kiválasztott időpont")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <p>{reservation.periodFrom + " - " + reservation.periodTo}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={5} className="details-container text-primary">
                                <div className="detail-box">
                                    <h5 className="title">{t("Ár")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <p>{reservation.agencyClientFinalPrice + " " + reservation.currency}</p>
                                    </div>
                                </div>
                                {reservation.localizedFinalPrice && (
                                    <div className="detail-box">
                                        <h5 className="title">{t("Végső ár helyi díjakkal")}</h5>
                                        <div className="availability d-flex align-items-center">
                                            <p>{reservation.localizedFinalPrice + " " + reservation.currency}</p>
                                        </div>
                                    </div>
                                )}
                                <div className="detail-box">
                                    <h5 className="title">{t("Foglalás időpontja")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <p>{reservation.createdDate}</p>
                                    </div>
                                </div>
                                {reservation.reservationStatus === "OPTION" && (
                                    <div className="detail-box">
                                        <h5 className="title">{t("Foglalás lejárata")}</h5>
                                        <div className="availability d-flex align-items-center">
                                            <p>{reservation.optionTill}</p>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    ) : (
                        <div class="lds-dual-ring"></div>
                    )}
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}
